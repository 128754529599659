@import url(native.css);

//colors
$color_suva_gray_approx: #888;
$color_blaze_orange_approx: #ff6600;
$black: black;

//fonts
$font_0: Cabin;
$font_1: sans-serif;
$font_2: Puritan;
$font_3: Philosopher;
$font_4: Anonymous Pro;
$font_5: Bitstream Vera Sans;
$font_6: Monaco;
$font_7: Courier;
$font_8: mono;

body {
  background-image: url('/images/square_bg.png');
  font-size: 20px;
}

a {
    color: $color_blaze_orange_approx;
    &:hover {
        text-decoration: underline;
    }
}

code {
    font-family: $font_4, $font_5, $font_6, $font_7, $font_8;
    font-size: 1rem;
    padding: 0 .15em .15em 0;
}

h1 {
    font-weight: normal;
    display: inline;
    a {
        color: $black;
        text-decoration: none;
    }
}

h3 {
  font-style: italic;
  font-weight: normal;
  font-size: 1em;
  padding: 1em;
}

h5 { color: $color_blaze_orange_approx }

hr {
    color: $color_blaze_orange_approx;
    background-color: $color_blaze_orange_approx;
    height: 2px;
}

img { border: 0; }

pre {
  padding: 1em;
  margin: 0 1em 0 1em;
}

.container {
    font-family: $font_0, $font_1;
    font-size: 20px;
    line-height: 1.5;
    > {
        header {
            display: block;
            padding-top: 1.5em;
            margin-bottom: 1em;
            > h1 {
                font-size: 3em;
                font-family: $font_2, $font_1;
                font-weight: normal;
                display: inline;
                margin: 0;
            }
        }
        footer {
            font-family: $font_3, $font_1;
            text-align: left;
            margin-left: .5em;
            font-style: italic;
            font-size: .8em;
            color: $color_suva_gray_approx;
        }
    }
}

.date {
  font-size: .9em;
  font-style: italic;
  margin: .25em 0 1em 0;
}

.summary {
  font-size: 20px;

  code { color: $black; }
  h1 { font-size: 1.25em; }

  .more {
    font-style: italic;
    text-align: right;
    padding-right: 1em;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

div#subtitle {
    color: $color_suva_gray_approx;
    font-style: italic;
    font-weight: normal;
}

#bio {
  font-family: $font_3, $font_1;
  font-size: .8em;
}
